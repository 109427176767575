@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?w2se7r');
  src:  url('../../assets/fonts/icomoon.eot?w2se7r#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon.ttf?w2se7r') format('truetype'),
    url('../../assets/fonts/icomoon.woff?w2se7r') format('woff'),
    url('../../assets/fonts/icomoon.svg?w2se7r#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-inventory:before {
  content: "\e90f";
}
.icon-selling:before {
  content: "\e95e";
}
.icon-selling-1:before {
  content: "\e96e";
}
.icon-selling-2:before {
  content: "\e970";
}
.icon-selling-3:before {
  content: "\e976";
}
.icon-events:before {
  content: "\e9a7";
}
.icon-purchase:before {
  content: "\e9c6";
}
.icon-dashboard-1:before {
  content: "\eb8e";
}
.icon-row-list:before {
  content: "\ebd6";
}
.icon-cards-list:before {
  content: "\ebd8";
}
.icon-row-list-1:before {
  content: "\ebe0";
}
.icon-plus:before {
  content: "\ec84";
}
.icon-menu-1:before {
  content: "\ecd5";
}
.icon-menu:before {
  content: "\ecd6";
}
.icon-filter:before {
  content: "\ecdc";
}
.icon-products:before {
  content: "\ecea";
}